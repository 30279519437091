<template>
  <section :class="classes">
    <div class="lead__inner">
      <div class="lead__media">
        <cdr-img
          v-if="loggedInCardholder"
          alt="Whimsical illustration of the R E I mastercard alongside outdoorsy
          banners announcing combined 5 percent and 10 percent rewards."
          src="https://www.rei.com/assets/credit-card/capital-one/rei-co-op-mastercard-lead/live.svg"
          class="lead__image"
        />
        <cdr-img
          v-else
          :alt="image?.alt"
          :src="image?.src"
          class="lead__image"
        />
        <div
          v-if="!isCardHolder"
          class="lead__manage-card"
        >
          {{ leadExistingCardholder }}
          <cdr-link
            :href="ctaSecondary?.target"
            :data-analytics-id="ctaSecondary?.text"
          >
            {{ ctaSecondary?.text }}
          </cdr-link>
        </div>
      </div>
      <div class="lead__content">
        <cdr-text
          v-if="!isDoubleRewardsActive"
          tag="span"
          class="lead__eyebrow"
        >
          {{ eyebrow }}
        </cdr-text>
        <cdr-text
          tag="h1"
          class="lead__heading"
        >
          <span
            v-if="isDoubleRewardsActive"
            v-html="doubleRewardsHeading"
          />
          <span
            v-else
            v-html="headingConverted"
          />
        </cdr-text>
        <cdr-text
          v-if="isDoubleRewardsActive"
          class="lead__double-rewards-text"
        >
          <span v-html="doubleRewardsLeadText" />
        </cdr-text>
        <template v-if="loggedInCardholder">
          <cdr-text
            v-if="!isDoubleRewardsActive"
            data-ui="lead-cardholder-text"
            class="lead__subheading"
          >
            <span v-html="cardHolderText" />
          </cdr-text>
          <div class="lead__ctas lead__ctas--top-margin">
            <cdr-button
              id="lead-cta-button"
              :href="personalizedTopCategory?.destination"
              class="lead__cta lead__cta--primary"
              tag="a"
              data-ui="lead-personalized-top-category-cta"
            >
              Shop {{ personalizedTopCategory?.description }}
            </cdr-button>
            <cdr-button
              id="lead-cta-button"
              :href="ctaSecondary?.target"
              class="lead__cta lead__cta--secondary"
              tag="a"
              data-analytics-id="hero_See if youre pre-approved"
              modifier="secondary"
              data-ui="lead-pre-approval-cta"
            >
              {{ ctaSecondary?.text }}
            </cdr-button>
          </div>
        </template>

        <template v-else>
          <cdr-text class="lead__cobrand-pre-approval-text">
            <span v-html="leadPreApprovalText" />
          </cdr-text>

          <div class="lead__ctas">
            <cdr-button
              id="lead-cta-button"
              :href="ctaPreApproval?.target"
              class="lead__cta lead__cta--primary"
              tag="a"
              data-analytics-id="hero_See if youre pre-approved"
            >
              {{ ctaPreApproval?.text }}
            </cdr-button>
            <div class="lead__secondary-cta-container">
              <cdr-link
                data-ui="lead-cta-skip-apply-now"
                class="lead__cta lead__cta--secondary"
                :href="ctaPrimary?.target"
              >
                {{ ctaSkipAndApplyText }}
              </cdr-link>
              <div class="lead__manage-card lead__manage-card--sm">
                {{ leadExistingCardholder }}
                <cdr-link
                  :href="ctaSecondary?.target"
                  :data-analytics-id="ctaSecondary?.text"
                >
                  {{ ctaSecondary?.text }}
                </cdr-link>
              </div>
            </div>
          </div>
          <cdr-text
            v-if="!isDoubleRewardsActive"
            class="lead__subheading"
          >
            <span v-html="subheadingConverted" />
          </cdr-text>
        </template>
      </div>

      <div class="lead__value-props">
        <cdr-list class="lead__value-prop-list">
          <li
            v-for="({ image: img, text, pill, footnote }, key) in valueProps"
            :key="key"
            class="lead__value-prop"
          >
            <cdr-img
              :aria-hidden="!img?.alt"
              :alt="img?.alt"
              :src="img?.src"
            />
            <div class="lead__value-prop-text">
              <span>{{ text }} <sup v-if="!!footnote">{{ footnote }}</sup> </span>
              <div
                v-if="pill"
                class="lead__value-prop-pill"
                v-html="pill"
              />
            </div>
          </li>
        </cdr-list>
      </div>
      <span
        class="lead__disclaimer"
        v-html="leadDisclaimer"
      />
    </div>
  </section>
</template>
<script setup>
import { computed } from 'vue';
import {
  CdrButton, CdrImg, CdrLink, CdrList, CdrText,
} from '@rei/cedar';
import convertToHtml from '@rei/rich-text-renderer';
import { unwrapParagraphTag } from '../../../utils/shared-adapters';

const props = defineProps({
  cardHolderText: {
    type: String,
    required: true,
  },
  ctaPreApproval: {
    type: Object,
    required: true,
  },
  ctaPrimary: {
    type: Object,
    required: true,
  },
  ctaSecondary: {
    type: Object,
    required: true,
  },
  ctaSkipAndApplyText: {
    type: String,
    required: true,
  },
  doubleRewardsHeading: {
    type: String,
    required: true,
  },
  doubleRewardsLeadText: {
    type: String,
    required: true,
  },
  eyebrow: {
    type: String,
    required: true,
  },
  heading: {
    type: Object,
    required: true,
  },
  image: {
    type: Object,
    required: true,
  },
  isCardHolder: {
    type: Boolean,
    default: false,
  },
  isDoubleRewardsActive: {
    type: Boolean,
    required: true,
  },
  isUserLoggedIn: {
    type: Boolean,
    default: false,
  },
  leadDisclaimer: {
    type: String,
    required: true,
  },
  leadExistingCardholder: {
    type: String,
    required: true,
  },
  leadPreApprovalText: {
    type: String,
    required: true,
  },
  personalizedTopCategory: {
    type: Object,
    default() {
      return {
        description: '',
        destination: '',
      };
    },
  },
  subheading: {
    type: Object,
    required: true,
  },
  valueProps: {
    type: Array,
    required: true,
  },
});

const loggedInCardholder = computed(() => props.isCardHolder && props.isUserLoggedIn);
const classes = computed(() => ({
  lead: true,
  'lead--2xr': props.isDoubleRewardsActive,
  'lead--is-cardholder': loggedInCardholder.value,
}));

const headingConverted = computed(() => convertToHtml(props?.heading, unwrapParagraphTag));

const subheadingConverted = computed(() => convertToHtml(props?.subheading));
</script>

<style lang="scss">
@import '@rei/cdr-component-variables/dist/scss/CdrButton.vars.scss';
@import '@rei/cdr-component-variables/dist/scss/options.vars.scss';
@import '@rei/cdr-component-variables/dist/scss/CdrLink.vars.scss';

.lead {
  background-color: $cdr-color-background-secondary;
}

.lead--is-cardholder {
  .lead__heading {
    margin-bottom: 1rem;
  }

  .lead__ctas {
    margin-top: 2.5rem;
    @include cdr-md-mq-up {
      margin-bottom: 0;
    }
  }

  .lead__cta {
    display: block;
    width: max-content;
    margin-right: auto;
    margin-left: auto;
    @include cdr-md-mq-up {
      margin-left: 0;
      margin-right: $cdr-space-one-x;
    }
  }

  .lead__cta--secondary {
    @include cdr-button-base-mixin;
    margin: 0 auto $cdr-space-one-x;
    @include cdr-md-mq-up {
      @include cdr-button-large-mixin;
      margin: 0;
    }
  }
}

.lead__content,
.lead__media {
  @include cdr-md-mq-up {
    max-width: 50vw;
  }
}

.lead__inner {
  @include cdr-container;
  text-align: center;
  @include cdr-text-body-300;
  padding-top: $cdr-space-one-x;
  @include cdr-md-mq-up {
    padding-top: 0;
    text-align: left;
    display: grid;
    grid-template-areas:
      "content    image"
      "value-prop value-prop"
      "disclaimer disclaimer";
    grid-template-columns: 1fr 1fr;
  }
}

.lead__skip-apply-now {
  font-weight: 500;
}

.lead__manage-card {
  @include cdr-text-utility-sans-200;
  display: none;
  position: relative;
  z-index: 1;
  margin-top: -$cdr-space-one-x;
  padding-left: 0;
  font-weight: 500;
  color: $cdr-color-text-primary;

  @include cdr-md-mq-up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $cdr-space-one-x;

    a {
      display: block;
    }
  }

  a,
  a:active,
  a:hover,
  a:visited,
  a:focus {
    color: inherit;
  }

  &--sm {
    display: block;
    margin-top: $cdr-space-half-x;
    font-weight: 400;

    @include cdr-md-mq-up {
      display: none;
    }
  }
}

.lead__media {
  margin-bottom: $cdr-space-half-x;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  @include cdr-md-mq-only {
    justify-content: center;
  }

  @include cdr-md-mq-up {
    flex-direction: column;
    align-items: flex-end;
    grid-area: image;
    padding-top: $cdr-space-four-x;
  }
}

.lead__content {
  @include cdr-md-mq-up {
    grid-area: content;
  }
  @include cdr-md-mq-up {
    padding: $cdr-space-four-x $cdr-space-four-x $cdr-space-four-x 0;
  }
}

.lead__image {
  width: 100%;
  position: relative;
  z-index: 1;
  aspect-ratio: 7 / 4;
  max-height: 280px;

  @include cdr-md-mq-up {
    max-height: initial;
    max-height: 321px;
  }
}

.lead__eyebrow,
.lead__heading {
  color: $cdr-color-text-brand;
}

.lead__eyebrow {
  @include cdr-text-utility-serif-400;
  display: inline-block;
  margin-bottom: $cdr-space-half-x;
  @include cdr-md-mq-up {
    @include cdr-text-utility-serif-600;
  }
}

.lead__heading {
  @include cdr-text-heading-display-800;
  margin-bottom: $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    @include cdr-text-heading-display-1200;
    margin-bottom: $cdr-space-two-x;
  }
}

.lead__double-rewards-text {
  max-width: 34rem;
  margin: 0 auto $cdr-space-one-and-a-half-x;

  span,
  sup {
    @include cdr-text-body-300;
  }

  strong,
  sup {
    font-weight: 600;
  }

  strong {
    padding-right: 0.6rem;
  }

  sup {
    font-size: 1.4rem;
    font-weight: 600;
    position: absolute;
    transform: translateY(-2px);
  }

  @include cdr-md-mq-up {
    max-width: 52rem;
    margin: 0 0 2rem;
  }
}

.lead__cobrand-pre-approval-text {
  @include cdr-text-utility-sans-200;
  margin-bottom: 0.6rem;
}

.lead__ctas {
  margin-bottom: $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    display: flex;
  }
}

.lead__cta--primary {
  margin-bottom: $cdr-space-one-x;
  white-space: nowrap;
  @include cdr-md-mq-up {
    margin-bottom: 0;
    @include cdr-button-large-mixin;
  }
}

.lead__ctas--top-margin {
  margin-top: $cdr-space-two-x;
}

.lead__cta--secondary {
  @include cdr-text-utility-sans-200;
  color: $cdr-color-text-primary;
  background-color: $cdr-color-background-secondary;
  font-weight: 500;

  @include cdr-md-mq-up {
    margin: 0 0 0 $cdr-space-one-x;
    @include cdr-button-large-mixin;
    @include cdr-text-utility-sans-300;
    font-weight: 500;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.lead__secondary-cta-container {
  @include cdr-md-mq-up {
    display: flex;
    align-items: center;
    & > a {
      display: block;
    }
  }
}

.lead__subheading {
  @include cdr-text-body-300;
  margin-bottom: $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    margin-bottom: 0;
  }

  a {
    @include cdr-link-base-mixin;
  }

  sup {
    font-size: 0.8rem;
  }

  @include cdr-sm-mq-only {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}

.lead__value-props {
  margin-bottom: $cdr-space-one-and-a-half-x;
  @include cdr-md-mq-up {
    grid-area: value-prop;
  }
}

.lead__value-prop-list {
  padding-left: 0;
  margin: 0;
  @include cdr-md-mq-up {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.lead__value-prop {
  display: grid;
  grid-template-columns: 38% 1fr;
  grid-auto-flow: column;
  align-content: center;
  margin-bottom: $cdr-space-three-quarter-x;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    justify-self: center;
    align-self: center;
    height: 50px;
    max-width: 90px;
  }

  sup {
    font-size: 0.8rem;
    font-weight: 600;
  }

  @include cdr-sm-mq-only {
    img {
      justify-self: end;
      margin-right: $cdr-space-two-x;
    }
  }
  @include cdr-md-mq-up {
    padding: 0;
    display: flex;
    margin-top: 0 !important;
    img {
      height: initial;
      max-width: initial;
    }
  }
}

.lead__value-prop-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  @include cdr-md-mq-up {
    margin-top: $cdr-space-half-x;
    max-width: 230px;
    align-self: center;
    padding-left: $cdr-space-one-x;
  }

  span {
    @include cdr-text-utility-sans-strong-300;
  }
}

.lead__value-prop-pill {
  align-self: baseline;
  @include pill;
  margin-top: $cdr-space-quarter-x;
  @include cdr-md-mq-up {
    margin-top: $cdr-space-half-x;
  }
}

.lead__disclaimer {
  @include cdr-text-eyebrow-100;
  display: inline-block;
  text-align: center;
  margin-bottom: $cdr-space-one-x;

  span {
    display: none;
    @include cdr-md-mq-up {
      display: inline;
    }
  }

  sup {
    font-size: 0.66rem;
    font-weight: 600;
  }

  @include cdr-md-mq-up {
    grid-area: disclaimer;
  }
}

.lead--2xr {
  .lead__heading {
    max-width: 32.7rem;
    margin: 0 auto $cdr-space-half-x;

    @include cdr-md-mq-up {
      max-width: 56.8rem;
      margin: 0 0 3.4rem;
    }
  }

  .lead__media {
    margin-bottom: 4rem;
    justify-content: center;
  }

  .lead__image {
    width: auto;
    aspect-ratio: initial;
    max-height: 22rem;
  }

  .lead__ctas {
    margin-bottom: 4rem;
  }

  .lead__manage-card {
    margin-top: $cdr-space-one-x;
  }

  @include cdr-md-mq-up {
    .lead__image {
      align-self: center;
      max-height: 33rem;
    }

    .lead__value-prop {
      position: relative;
    }

    .lead__value-prop-pill {
      position: absolute;
      bottom: -2rem;
    }

    .lead__disclaimer {
      margin-top: $cdr-space-three-quarter-x;
    }
  }
}

</style>
